// src/redux/slices/airdropSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Leader, User} from "../types";

interface LeaderboardState {
    leaderboard: Leader[];
    weeklyLeaderboard: Leader[];
    isFriendsLeaderboard: boolean;
    totalUsers: number;
    timeLeft: number;
}

const initialState: LeaderboardState = {
    leaderboard: [],
    weeklyLeaderboard: [],
    isFriendsLeaderboard: false,
    totalUsers: 0,
    timeLeft: 0,
};

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {
        setLeaderboard(state, action: PayloadAction<Leader[]>) {
            state.leaderboard = action.payload;
        },
        setWeeklyLeaderboard(state, action: PayloadAction<Leader[]>) {
            state.weeklyLeaderboard = action.payload;
        },
        setTotalUsers(state, action: PayloadAction<number>) {
            state.totalUsers = action.payload;
        },
        setTimeLeft(state, action: PayloadAction<number>) {
            state.timeLeft = action.payload;
        },
        toggleLeaderboard(state) {
            state.isFriendsLeaderboard = !state.isFriendsLeaderboard;
        },
        // Additional reducers can be added here
    },
});

export const { setLeaderboard, setWeeklyLeaderboard, toggleLeaderboard, setTotalUsers, setTimeLeft } = leaderboardSlice.actions;
export default leaderboardSlice.reducer;
