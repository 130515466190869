// src/pages/AirdropPage.tsx
import { useDispatch, useSelector } from "react-redux";
import { toggleLeaderboard } from "../../redux/slices/leaderboardSlice";
import { RootState } from "../../redux/store";
import LeadersList from '../../components/LeadersList';
import PageContainer from "../../components/common/PageContainer";
import s from './LeaderboardPage.module.css';
import Text from "../../components/common/Text";
import { useEffect, useState } from "react";

const LeaderboardPage: React.FC = () => {
    const { leaderboard, weeklyLeaderboard } = useSelector((state: RootState) => state.leaderboard);
    const { user } = useSelector((state: RootState) => state.game);

    const dispatch = useDispatch();
    const isFriendsLeaderboard = useSelector((state: RootState) => state.leaderboard.isFriendsLeaderboard);
    const toggle = () => dispatch(toggleLeaderboard())

    const { totalUsers, timeLeft } = useSelector((state: RootState) => state.leaderboard);

    const [localTimer, setLocalTimer] = useState(timeLeft);
    const [daysLeft, setDaysLeft] = useState('0');
    const [hoursLeft, setHoursLeft] = useState('00');
    const [minutesLeft, setMinutesLeft] = useState('00');
    const [secondsLeft, setSecondsLeft] = useState('00');

    useEffect(() => {
        setDaysLeft(String(Math.floor(localTimer / 86400))); // Calculate days
        setHoursLeft(String(Math.floor((localTimer % 86400) / 3600)).padStart(2, '0')); // Remaining hours after days
        setMinutesLeft(String(Math.floor((localTimer % 3600) / 60)).padStart(2, '0')); // Remaining minutes after hours
        setSecondsLeft(String(localTimer % 60).padStart(2, '0')); // Remaining seconds

        const interval = setInterval(() => {
            setLocalTimer(localTimer - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [localTimer]);


    return (
        <PageContainer>
            <button className={s.toggleBtn}
                style={{ justifyContent: `${!isFriendsLeaderboard ? "flex-start" : "flex-end"}` }}
                onClick={toggle} >
                <div className={!isFriendsLeaderboard ? s.buttonBg : s.buttonNoBg}>
                    <p className={s.toggleBtnText}>top 500</p>
                </div>
                <div className={!isFriendsLeaderboard ? s.buttonNoBg : s.buttonBg}>
                    <p className={s.toggleBtnText}>7 days</p>
                </div>

                <img className={s.sheepImg} src={!isFriendsLeaderboard ? "/images/leaders/leaders_sheep_7days.webp" : "/images/leaders/leaders_sheep_top500.webp"} alt="sheep" />
            </button>

            <img className={s.heroImg} src={!isFriendsLeaderboard ? "/images/leaders/leaders_text_7days.webp" : "/images/leaders/leaders_text_top500.webp"} alt="sheep" />

            <div className={s.headerContainer}>
                <div className={s.titleOnBaseContainer}>
                    <div className={s.titleOnBase}>
                        <p className={s.text}>rank</p>
                    </div>

                    <div className={s.titleCounterBox}>
                        <p className={s.titleCounter}>{!isFriendsLeaderboard ? "total users:" : "time left:"}</p>
                        <p className={s.text}>{!isFriendsLeaderboard ? totalUsers : `${daysLeft}d ${hoursLeft}:${minutesLeft}:${secondsLeft}`}</p>
                    </div>

                    <div className={s.titleOnBase}>
                        <p className={s.text}>prizes</p>
                    </div>
                </div>
            </div>

            <LeadersList arrayItems={isFriendsLeaderboard ? weeklyLeaderboard : leaderboard} isWeekly={isFriendsLeaderboard} currentUser={user} />

        </PageContainer>
    );
};

export default LeaderboardPage;
