import s from './ItemListBG.module.css'

// example < ItemListBG size = { "big"} index = { index } length = { arrayItems.length } >
interface ItemListComponentProps {
    size: "small" | "big";
    index: number;
    length: number;
    children: React.ReactNode
}

const styles = {
    big: {
        backgroundImage: 'url("/images/tasks/panel.webp")',
        aspectRatio: '328 / 72',
    },
    small: {
        backgroundImage: 'url("/images/bg_list_small.webp")',
        aspectRatio: '328 / 48', //??????
    },
};

const ItemListBG: React.FC<ItemListComponentProps> = ({ size, index, length, children }) => {

    const style = size === "small" ? styles.small : styles.big;

    return (
        <div key={index} className={s.listItem} style={style}>
            {index < length - 1 && <img className={s.ropesBG} style={{ animationDelay: `${index * 0.01}s` }} src={`/images/ropes_wide.webp`} alt="ropes" />}

            {children}
        </div>
    )
}

export default ItemListBG