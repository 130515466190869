import { useState } from "react"
import SimpleButton from "../common/SimpleButton";
import s from './ModalTasksContent.module.css'
import { Task } from "../../redux/types";
import telegramWebAppAPI from "../../services/telegram";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect } from "react";
import { setCheckTaskResult } from "../../redux/slices/tasksSlice";
import Spinner from "../common/Spinner";
import { showToast } from "../../redux/slices/gameSlice";

interface ModalTasksContentProps {
    task: Task;
    closeModal: () => void;
}

const ModalTasksContent: React.FC<ModalTasksContentProps> = ({ task, closeModal }) => {
    const dispatch = useDispatch();
    const runningRequest = useSelector((state: RootState) => state.game.runningRequest);
    const checkTaskResult = useSelector((state: RootState) => state.tasks.checkTaskResult);
    // === SHAKING ON CLICK
    const [isShaking, setIsShaking] = useState(false);

    const [isClicked, setIsClicked] = useState(false);
    useEffect(() => {
        if (!runningRequest) setIsClicked(false);
    }, [runningRequest]);

    useEffect(() => {
        if (checkTaskResult === 1) {
            closeModal();
            dispatch(showToast('Task completed!'));
        } else if (checkTaskResult === 0) {
            setIsShaking(true);
            setTimeout(() => {
                setIsShaking(false);
            }, 500); // Длительность анимации shake
        }
        dispatch(setCheckTaskResult(-1));
    }, [checkTaskResult, closeModal, dispatch]);



    const handleButtonClick = () => {
        setIsClicked(true);
        dispatch({ type: 'tasks/checkAndMarkTask', payload: task.id });
    };

    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>

            <div className={s.avaPanel}>
                <img className={s.baseImg} src={`/images/tasks/tg.webp`} alt="avatar" />
            </div>

            <p className={s.textMain}>{task.title}</p>

            <div className={s.rewardBox}>
                <img className={s.coinImg} src={`/images/header/coin.webp`} alt="coin" />
                <p className={s.reward}>+{task.reward}</p>
            </div>

            <button className={s.btnSubscribe} onClick={() => { telegramWebAppAPI.openTelegramLink(task.url); }} type="button">
                <p className={s.textBtn}>subscribe</p>
            </button>


            <div className={s.checkBox}>
                <img className={s.divider} src={`/images/modalTasksContent/divider.webp`} alt="devider" />
                <div className={s.checkTextBox}>

                    <p className={s.textCheck}>Already done?</p>

                    <button
                        className={`${s.btnCheck} ${isShaking ? s.shake : ''}`}
                        onClick={handleButtonClick}
                        disabled={isClicked}
                        type="button"
                    >
                        {isClicked ? <Spinner /> : <p className={s.textBtn}>check</p>}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalTasksContent;