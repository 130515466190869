import s from './PageHeader.module.css'

interface PageHeaderProps {
    title: string,
    fontSize: number
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, fontSize }) => {
    return (
        <div className={s.titleBox}>
            <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
            <img className={s.paper} src={`/images/header/paper.webp`} alt="paper" />
            <p className={s.title} style={{ fontSize: `${fontSize}em` }}>{title}</p>
        </div>
    )
}

export default PageHeader