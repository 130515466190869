// src/redux/sagas/leaderboardSaga.ts
import { takeEvery, call, put, ForkEffect } from 'redux-saga/effects';
import {setLeaderboard, setTimeLeft, setTotalUsers, setWeeklyLeaderboard} from '../slices/leaderboardSlice';
import {apiCaller, waitForAuthorization} from "./apiCaller";

function* handleLeaderboard(): Generator<any, void, any> {
    // Wait until the authorization is complete
    yield waitForAuthorization();

    try {
        const info = yield call(apiCaller, { apiFuncName: 'fetchLeaderboard', args: {}});
        yield put(setLeaderboard(info));

        const weeklyInfo = yield call(apiCaller, { apiFuncName: 'fetchWeeklyLeaderboard', args: {}});
        yield put(setWeeklyLeaderboard(weeklyInfo));

        const {users, time} = yield call(apiCaller, { apiFuncName: 'fetchMetaData', args: {}});
        yield put(setTotalUsers(users));
        yield put(setTimeLeft(time));

    } catch (error) {
        console.error(error);
    }
}

export default function* leaderboardSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery('leaderboard/fetchLeaderboard', handleLeaderboard);
}
