import Modal from "../../common/Modal";
import useModal from "../../../services/useModal";
import SimpleButton from '../SimpleButton'
import s from './ProgressBarMain.module.css'
import ModalFreeEnergyContent from "../../ModalFreeEnergyContent";
import {setCurrentModal} from "../../../redux/slices/gameSlice";
import {useDispatch} from "react-redux";

interface ProgressBarMainProps {
    img: string
    completeWidth: number
    barText: string
    isGameOnGamePage?: boolean
}

const ProgressBarMain: React.FC<ProgressBarMainProps> = ({ img, completeWidth, barText, isGameOnGamePage }) => {
    // const { images } = useImageContext();
    const dispatch = useDispatch();

    // ==== MODAL FreeEnergy=====
    const { isOpen: isModalOpenFreeEnergy,
        isFadingOut: isFadingOutFreeEnergy,
        openModal: openModalFreeEnergy,
        closeModal: closeModalFreeEnergy } = useModal();

    const barContainerClass = isGameOnGamePage
        ? `${s.barContainer} ${s.barContainerAnimate}`
        : s.barContainer;

    return (
        <>
            <SimpleButton className={s.btnBarSize} onClick={openModalFreeEnergy}>
                <div className={barContainerClass}>
                    {img === "energy" && <img className={s.imgEnergy} src={`/images/game_energy.webp`} alt="energy" />}
                    {img === "scissors" && <img className={s.imgScissors} src={`/images/scissors.webp`} alt="scissors" />}
                    <div className={s.barComplete} style={{ width: `${completeWidth}vw` }}></div>
                    <img className={s.barPanelTop} src={`/images/game_progress_panel_top.webp`} alt="barPanelTop" />
                    <p className={s.barText}>{barText}</p>
                </div>
            </SimpleButton>

            {isModalOpenFreeEnergy && <Modal closeModal={closeModalFreeEnergy} isFadingOut={isFadingOutFreeEnergy} blocking={true}>
                <ModalFreeEnergyContent onClick={() => {closeModalFreeEnergy(); dispatch(setCurrentModal('boost'))}} closeModal={closeModalFreeEnergy} />
            </Modal>}
        </>
    )
}

export default ProgressBarMain