import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

import RiveWrapper from "./RiveWrapper";
import ProgressBarMain from '../../components/common/ProgressBarMain';
import s from './GamePage.module.css';
import AutotapAirdrop from '../../components/AutotapAirdrop';
import ChickRiveWrapper from "./ChickRiveWrapper";


const GamePage: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.game.user);
    const dismissedPopup = useSelector((state: RootState) => state.game.dismissedTasksPopup);

    const { tasks } = useSelector((state: RootState) => state.tasks);


    let [incompleteTasks, setIncompleteTasks] = useState<number>(0);
    useEffect(() => {
        const taskStatus = new Map<number, number>();

        if(user.completed_tasks) {
            user.completed_tasks.forEach((taskId) => {
                taskStatus.set(taskId, taskId);
            })
        }

        let rewardTtl = 0;
        tasks.filter((el) => !taskStatus.has(el.id)).map((el) => rewardTtl += el.reward);

        setIncompleteTasks(rewardTtl);
    }, [tasks, user]);

    const handleTap = () => {
        if (user.energy > 0) {
            dispatch({ type: 'game/addTaps' }); // Adjust the coin increment logic as needed
        }
    };

    const completeWidth = (current: number, max: number) => current / max * 54
    const currentWidth = completeWidth(user.energy, user.max_energy);
    const autoTapSeconds = user.auto_tap_seconds || 0;

    return (
        <div className={s.pageContainer}>
            <ProgressBarMain img={"energy"} completeWidth={currentWidth}
                barText={`${user.energy} / ${user.max_energy}`} isGameOnGamePage={true} />

            <AutotapAirdrop rewardSum={incompleteTasks} dismissPopup={dismissedPopup} address={user.airdrop_address} tapPower={user.tap_power || 1} autoTapSeconds={autoTapSeconds} />

            <div className={s.tapImage}>
                <RiveWrapper energy={user.energy} onClick={handleTap} autoTapEnabled={autoTapSeconds > 0} />
                {autoTapSeconds > 0 && <ChickRiveWrapper />}
            </div>
        </div>
    );
};



export default GamePage;
