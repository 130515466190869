import SimpleButton from '../common/SimpleButton'
import s from "./ModalAirdropContent.module.css"
import { useDispatch } from "react-redux";
import { useState } from "react";

interface ModalAirdropContentProps {
    existingAddress: string;
    closeModal: () => void;
}

// [1-9A-HJ-NP-Za-km-z]{32,44} - regex

const ModalAirdropContent: React.FC<ModalAirdropContentProps> = ({ existingAddress, closeModal }) => {
    const dispatch = useDispatch();
    const [input, setInput] = useState<string>(existingAddress);



    const isRegistered = existingAddress !== '' && typeof existingAddress !== 'undefined';

    const btnText = !isRegistered ? "register" : "unregister"
    const btnBG = !isRegistered ? "/images/modalTasksContent/btn_blue.webp" : "/images/modalTasksContent/btn_red.webp"

    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <img className={s.planeImg} src={`/images/airdrop_modal_plane.webp`} alt="plane" />

            <h1 className={s.textTitle}>AIRDROP!!!</h1>

            {!isRegistered ?
                <div className={s.textBox}>
                    <p className={s.text}>register your solana address</p>
                    <p className={s.text}>for airdrop</p>
                </div>
                :
                <div className={s.textBox}>
                    <p className={s.text}>your solana address for airdrop</p>
                    <p className={s.text}>is successfully registered</p>
                </div>
            }

            <input
                onChange={(e) => setInput(e.target.value)}
                disabled={isRegistered}
                type="text"
                className={s.input}
                placeholder={isRegistered ? existingAddress : "paste your Solana address"}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
            />

            <div className={s.textBoxNote}>
                <p className={s.textNote}>(!) NOTE: Your Solana address can only be used with Web3 Wallets (such as TrustWallet, SafePal, Phantom, Solflare and others). Do not use the address from centralized exchanges.</p>
            </div>

            <button className={s.btn} style={{ backgroundImage: `url(${btnBG})` }} onClick={() => {
                dispatch({ type: 'game/setAirdropAddress', payload: isRegistered ? '' : input });
                closeModal();
            }} type="button">
                <p className={s.text}>{btnText}</p>
            </button>
        </div>
    )
}

export default ModalAirdropContent