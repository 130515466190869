import SimpleButton from '../common/SimpleButton'
import s from "./ModalEnergyIncreaseContent.module.css"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { tap } from "node:test/reporters";

interface ModalEnergyIncreaseContentProps {
    closeModal: () => void;
    onClick: () => void;
    tapPower: number;
}

const ModalEnergyIncreaseContent: React.FC<ModalEnergyIncreaseContentProps> = ({ closeModal, onClick, tapPower }) => {

    const list = [
        { descr: "Coins per tap", value: tapPower },
    ]

    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>

            <div className={s.listBox}>
                {list.map((item, index) => (
                    <div className={s.listItem} key={index}>
                        <p className={s.textTitle}>{item.descr}</p>
                        <div className={s.textBox}>
                            <p className={s.text}>{item.value}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className={s.listBox}>
                <p className={s.textNote}>You can increase the tap to a maximum of +16!</p>
                <p className={s.textNote}>+1 Tap saves ~240 minutes per month</p>
            </div>


            <button className={s.btn} onClick={onClick} type="button">
                <p className={s.textBtn}>UPGRADE</p>
            </button>
        </div>
    )
}

export default ModalEnergyIncreaseContent