import riveWASMResource from "@rive-app/canvas/rive.wasm";
import { useRive, Layout, Fit, Alignment, RuntimeLoader } from "@rive-app/react-canvas";
import s from "./GamePage.module.css"
import React, { useState } from "react";

RuntimeLoader.setWasmUrl(riveWASMResource);


// Wrapper component to isolate useRive logic that
// renders the <RiveComponent />
const ChickRiveWrapper:React.FC = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    const { RiveComponent } = useRive({
        src: '/anim/hit.riv',
        autoplay: true,
        onLoad: () => { setIsLoaded(true) },
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.Center,
        })
    });


    return <div className={`${s.riveChickContainer} ${isLoaded ? s.visible : ''}`}>
        <RiveComponent
            // style={{ width: '450px', height: '450px', paddingLeft: "2.42vw" }} // new
            style={{ width: '100%', height: '80%', paddingLeft: "2.42vw" }} // new
        />
    </div>;
};

export default ChickRiveWrapper;
