import React, { useEffect, ReactNode } from 'react';
import s from "./Modal.module.css"

interface ModalProps {
    closeModal?: () => void;
    children: ReactNode;
    blocking?: boolean;
    allowHeader?: boolean;
    isFadingOut?: boolean
}

const Modal: React.FC<ModalProps> = ({
    closeModal = () => { },
    children,
    blocking = false,
    allowHeader = false,
    isFadingOut
}) => {
    // ON ECS CLOSE ==============
    useEffect(() => {
        const onEscPress = (e: KeyboardEvent) => {
            if (e.code === 'Escape') {
                closeModal();
            }
        };

        document.body.style.overflow = 'hidden';
        window.addEventListener('keydown', onEscPress);

        return () => {
            window.removeEventListener('keydown', onEscPress);
            document.body.removeAttribute('style');
        };
    }, [closeModal]);

    // ON BACKDROP CLICK CLOSE ==============
    const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    return (
        <div className={`${s.backdrop} ${isFadingOut ? s.fadeOut : ''}`}
            style={{
                zIndex: (blocking ? (allowHeader ? 520 : 600) : 450),
                alignItems: blocking ? "center" : "flex-start"
            }} onClick={handleBackdropClick}>
            {children}
        </div>
    );
};

export default Modal;
