import riveWASMResource from "@rive-app/canvas/rive.wasm";
import { useRive, Layout, Fit, Alignment, RuntimeLoader } from "@rive-app/react-canvas";
import s from "./GamePage.module.css"
import React, { useEffect, useState } from "react";

RuntimeLoader.setWasmUrl(riveWASMResource);

interface RiveWrapperProps {
    energy: number;
    onClick: () => void;
    autoTapEnabled: boolean;
}

// Wrapper component to isolate useRive logic that
// renders the <RiveComponent />
const RiveWrapper: React.FC<RiveWrapperProps> = ({ onClick, energy, autoTapEnabled }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const { rive, RiveComponent } = useRive({
        src: '/anim/sheep.riv',
        stateMachines: "idle",
        autoplay: true,
        onLoad: () => { setIsLoaded(true) },
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        })
    });


    useEffect(() => {
        if (!autoTapEnabled || !rive) return;

        const interval = setInterval(() => {
            if (rive) {
                rive.play('boo')
            }
        }, 500);

        return () => clearInterval(interval);
    }, [autoTapEnabled, rive]);


    return <div className={`${s.riveComponentContainer} ${isLoaded ? s.visible : ''}`}>
        <RiveComponent
            onTouchStart={() => {
                if (!rive || !energy || autoTapEnabled) return;
                rive.stop('scissor')
                rive.play('scissor')
                rive.play('boo')
                onClick();
            }}
            // style={{ width: '450px', height: '450px', paddingLeft: "2.42vw" }} // new
            style={{ width: '100%', height: '80%', paddingLeft: "2.42vw" }} // new
        />
    </div>;
};

export default RiveWrapper;
