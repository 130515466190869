import PageHeader from '../common/PageHeader';
import SimpleButton from '../common/SimpleButton';
import Text from '../common/Text';
import s from './ModalRulesContent.module.css'

const tableData = [
    { title: "1 place", value: "$300" },
    { title: "2 place", value: "$200" },
    { title: "3 place", value: "$100" },
    { title: "4 place", value: "$90" },
    { title: "5 place", value: "$85" },
    { title: "6 place", value: "$80" },
    { title: "7 place", value: "$65" },
    { title: "8 place", value: "$60" },
    { title: "9 place", value: "$55" },
    { title: "10 place", value: "$50" },
    { title: "11-20 place", value: "$25" },
    { title: "21-25 place", value: "$10" },
]

interface ModalRulesContentProps {
    closeModal: () => void;
}

const ModalRulesContent: React.FC<ModalRulesContentProps> = ({ closeModal }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <img className={s.imgConfetti} src={"/images/leaders/confetti.webp"} alt="confetti" />
            <PageHeader title={"Rules"} fontSize={1.25} />

            <div className={s.contentContainer}>
                <img className={s.imgCup} src={"/images/header/rules_cup.webp"} alt="cup" />

                <div className={s.textBox}>
                    <Text fontSize={1.5} lineHeight={1.5} gradientColors={["--gradient-top", "--gradient-bot"]} uppercase={true}>🏆 top 500 leaderbord</Text>
                    <Text fontSize={1} lineHeight={1.25} uppercase={true}>Prize Pool: $30,000 in USDT + $BAA tokens</Text>
                    <Text fontSize={0.65} lineHeight={1} uppercase={true}>This leaderboard counts all your actions in the game! Shear the Black Sheep, invite friends,
                        complete tasks and buy xBAA coins to increase your rank.</Text>
                    <Text fontSize={0.55} lineHeight={1} uppercase={true}>🗓 The date of the reward distribution will be announced very soon!</Text>
                </div>

                <div className={s.textBox}>
                    <Text fontSize={1.5} lineHeight={1.5} gradientColors={["--gradient-top", "--gradient-bot"]} uppercase={true}>🏆 7 DAYS Leaderboard:</Text>
                    <Text fontSize={0.65} lineHeight={1} uppercase={true}>This leaderboard only counts stats from invited referrals.</Text>
                    <Text fontSize={0.65} lineHeight={1} uppercase={true}>Participate in a weekly competition with a prize pool of $1400! Invite more friends and get paid to your Solana address every week if you make it to the TOP-25!</Text>

                    <div className={s.table}>
                        {tableData.map((item, index) => (
                            <div key={index} className={s.cell}>
                                <Text fontSize={0.5} lineHeight={0.6} gradientColors={["--gradient-top", "--gradient-bot"]} uppercase={true}>{item.title}</Text>
                                <Text fontSize={0.75} lineHeight={1} uppercase={true}>{item.value}</Text>
                            </div>
                        ))}
                    </div>

                    <Text fontSize={0.4} lineHeight={1} uppercase={true}>*The race starts at 20:00 UTC every Wednesday and ends at 20:00 UTC exactly one week later.</Text>
                    <Text fontSize={0.65} lineHeight={1} uppercase={true}>Hurry up, you only have 7 DAYS!</Text>
                </div>

                <div className={s.textBox}>
                    <Text fontSize={0.65} lineHeight={1} uppercase={true}>📣 Don't forget to save the address of your Solana wallet that supports USDT and other tokens on the
                        SOL network (click on the AIRDROP icon on the main game screen).</Text>
                </div>

                <div className={s.textBox}>
                    <Text fontSize={1} lineHeight={1.25} uppercase={true}>⚠️ ATTENTION!</Text>
                    <Text fontSize={0.65} lineHeight={1} uppercase={true}>Only active referrals are counted. We strongly discourage the use of bots or fake pages, - unscrupulous participants will be banned for the use of scrambling!</Text>
                </div>
            </div>
        </div>
    )
}

export default ModalRulesContent