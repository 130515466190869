import SimpleButton from '../common/SimpleButton'
import s from "./ModalFreeEnergyContent.module.css"

interface ModalAFreeEnergyContentProps {
    closeModal: () => void;
    onClick: () => void;
}

const ModalFreeEnergyContent: React.FC<ModalAFreeEnergyContentProps> = ({ closeModal, onClick }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>

            <h1 className={s.textTitle}>FREE Wool Energy Regeneration:</h1>

            <div className={s.textBox}>
                <p className={s.text}>Available every 24 hours</p>
            </div>

            <p className={s.textNote}>You can boost your energy level for inviting friend and for Telegram Stars ⭐️</p>

            <button className={s.btn} onClick={onClick} type="button">
                <p className={s.textBtn}>UPGRADE</p>
            </button>
        </div>
    )
}

export default ModalFreeEnergyContent