import { useEffect, useState } from "react"
import ProgressBar from "../common/ProgressBar";
import s from './BoostList.module.css'
import { Boost, BoostStatus, BoostType } from "../../redux/types";
import Spinner from "../common/Spinner";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CountdownTimer from "./CountdownTimer";
import Text from "../common/Text";
import SimpleButton from "../common/SimpleButton";

const BoostListItem: React.FC<{ item: Boost, boostStatus: BoostStatus | undefined, onBoostClick: (boost: Boost) => void; index: number, arrLength: number }> = ({ item, onBoostClick, boostStatus, index, arrLength }) => {
    const runningRequest = useSelector((state: RootState) => state.game.runningRequest);
    let currentProgress = boostStatus ? boostStatus.count : 0;
    const completed = currentProgress >= item.max_count;
    const claimed = boostStatus ? boostStatus.status === 1 : false;
    if (currentProgress > item.max_count) currentProgress = item.max_count;

    // === LOADER CLICK ======
    const [isClicked, setIsClicked] = useState(false);
    useEffect(() => {
        if (!runningRequest) setIsClicked(false);
    }, [runningRequest]);

    const handleButtonClick = () => {
        setIsClicked(true);
        onBoostClick(item);
    };

    return (
        <div className={s.listItemBox}>
            {index < arrLength - 1 && <img className={s.ropesBG} style={{ animationDelay: `${index * 0.01}s` }} src={`/images/ropes.webp`} alt="ropes" />}

            {claimed && <div className={s.opacity}></div>}

            <div className={`${s.listItem} ${claimed ? s.listItemDone : ''}`} style={{ animationDelay: `${index * 0.01}s` }}>
                <div className={s.rewardBox}>
                    {item.type === BoostType.BUY_BAA && <>
                        <img className={s.iconImg} src={`/images/boost/buy_baa.webp`} alt="energy" />
                        <p className={s.reward}>{item.description}</p>
                    </>}

                    {item.type === BoostType.PURCHASE_TAPS && <>
                        <img className={s.iconImg} src={`/images/boost/shear.webp`} alt="energy" />
                        <div className={s.saleBase}>
                            <p className={s.saleText}>50% off</p>
                        </div>
                        <p className={s.reward}>{item.description}</p>
                    </>}

                    {item.type !== BoostType.BUY_BAA && item.type !== BoostType.PURCHASE_TAPS && <>
                        <img className={s.energyImg} src={`/images/boost/energy.webp`} alt="energy" />
                        <p className={s.reward}>{item.description}</p>
                    </>}
                </div>

                <div className={s.progressBarContainer}>

                    {/* todo: add current progress */}
                    {claimed && boostStatus && item.type === BoostType.DAILY_ENERGY && <CountdownTimer secondsPassed={boostStatus?.seconds_passed} />}
                    {item.type === BoostType.BUY_BAA && <>{item.description}</>}

                    <div className={s.purcaseTapsBox}>
                        <div className={s.innerBox}>
                            <p className={s.mainText}>{item.title}</p>
                            {item.type === BoostType.PURCHASE_TAPS && <div className={s.descrBase}>
                                <p className={s.descrTextPurchase}>Saves 240 mins per months</p>
                                {/* <Text fontSize={0.5} lineHeight={0.5} color={"--header-text"} uppercase={true}>Saves 240 mins per months</Text> */}
                            </div>}
                            {item.type === BoostType.BUY_BAA && <div className={s.descrBase}>
                                <p className={s.descrTextBuy}>Raise your rank on the leaderboard and win up to 30k$ prize</p>
                                {/* <Text fontSize={0.4} color={"--header-text"} uppercase={true}>Raise your rank on the leaderboard and win up to 30k$ prize</Text> */}
                            </div>}
                            {item.type !== BoostType.DAILY_ENERGY && item.type !== BoostType.BUY_BAA && item.type !== BoostType.PURCHASE_TAPS && <ProgressBar current={currentProgress} max={item.max_count} />}
                        </div>
                        <div className={s.innerBox}>
                            {(item?.price || 0) > 0 && <div className={s.tapsPrice}>
                                <p className={s.mainText}>{item.price}</p>
                                <img className={s.btnStarImg} src={"/images/leaders/star.webp"} alt="star" />
                            </div>}
                            {(item?.old_price || 0) > 0 &&
                                <div className={s.tapsPrice}>
                                    <p className={s.oldPriceText}>{item.old_price}</p>
                                    <img className={s.littleStarImg} src={"/images/leaders/star.webp"} alt="star" />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* todo: add condition */}

                {/* checkbox */}
                {completed && claimed && <img className={s.checkedImg} src={`/images/btn_checked.webp`} alt="checked" />}

                {/* yellow CLAIM! btn */}
                {completed && !claimed && <button
                    className={`${s.btn} ${s.btnClaim}`}
                    onClick={handleButtonClick}
                    type="button"
                    disabled={!completed || isClicked}
                >
                    {isClicked ? <Spinner /> : <p className={s.mainText}>{"claim!"}</p>}
                </button>}

                {/* blue GO! btn */}
                {item.type < BoostType.PURCHASE_TAPS && !claimed && !completed &&
                    <button
                        className={`${s.btn} ${s.btnGo}`}
                        onClick={handleButtonClick}
                        type="button"
                    >
                        <p className={s.mainText}>{"go!"}</p>
                    </button>
                }

                {item.type >= BoostType.PURCHASE_TAPS && !claimed && !completed &&
                    <SimpleButton className={s.starsBtn} onClick={handleButtonClick} type="button" imageUrl={`/images/autotap/btn_on.webp`}>
                        <Text fontSize={1.25} lineHeight={1.5} color={"--white"} uppercase={true}>buy</Text>
                    </SimpleButton>
                }

            </div>
        </div >
    )
}

export default BoostListItem