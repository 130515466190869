import s from "./FrensList.module.css";
import FrensListItem from "./FrensListItem";
import { List, AutoSizer, InfiniteLoader } from "react-virtualized";
import { User } from "../../redux/types";
import { fetchFriends } from "../../services/api";
import { useEffect, useState, useCallback } from "react";

interface FrensListProps {
    totalFriends: number;
    level: number;
}

const FrensList: React.FC<FrensListProps> = ({ totalFriends, level }) => {
    const [arrayItems, setArrayItems] = useState<User[]>([]);
    const [loaderKey, setLoaderKey] = useState(0); // Key to force reload

    // Reset the arrayItems and loaderKey when totalFriends changes
    useEffect(() => {
        if (isNaN(totalFriends)) totalFriends = 0;

        setArrayItems(new Array(totalFriends).fill(null)); // Reset arrayItems to match totalFriends
        setLoaderKey(prevKey => prevKey + 1); // Increment key to recreate InfiniteLoader and List
    }, [totalFriends, level]);

    const rowRenderer = useCallback(
        ({ key, index, style }: { key: string; index: number; style: React.CSSProperties }) => {
            const item = arrayItems[index];
            return (
                <div key={key} style={style}>
                    {item && <FrensListItem key={index} item={item} index={index} arrLength={arrayItems.length} />}
                </div>
            );
        },
        [arrayItems]
    );

    const rowHeight = useCallback(
        ({ index }: { index: number }) => {
            const baseHeight = window.innerHeight > 730 ? window.innerHeight * 0.064 : window.innerHeight * 0.0727;
            return index === arrayItems.length - 1 ? baseHeight * 1.65 : baseHeight;
        },
        [arrayItems]
    );

    const isRowLoaded = useCallback(
        ({ index }: { index: number }) => !!arrayItems[index],
        [arrayItems]
    );

    const loadMoreRows = useCallback(
        ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }): Promise<void> => {
            return fetchFriends(level, startIndex, stopIndex).then(result => {
                const newItems = result.friends;
                setArrayItems(prevItems => {
                    const updatedItems = [...prevItems];
                    for (let i = startIndex; i <= stopIndex; i++) {
                        updatedItems[i] = newItems[i - startIndex];
                    }
                    return updatedItems;
                });
            });
        },
        [level]
    );

    return (
        <div className={s.list}>
            <InfiniteLoader
                key={loaderKey}
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                minimumBatchSize={100}
                rowCount={totalFriends * 10}
            >
                {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                ref={registerChild}
                                onRowsRendered={onRowsRendered}
                                width={width}
                                height={height || window.innerHeight}
                                rowCount={totalFriends}
                                rowHeight={rowHeight}
                                rowRenderer={rowRenderer}
                            />
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </div>
    );
};

export default FrensList;
