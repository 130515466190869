// src/redux/types.ts
export interface User {
    id: number;
    username: string;
    language_code: string;
    avatar: string;
    tokens: number;  // Include score property
    energy: number;  // Include score property
    airdrop_address: string;
    max_energy: number;  // Include score property
    boosts: { [key: number]: BoostStatus },
    tasks: { [key: number]: TaskStatus },
    position: number;
    reward: number;
    friends: { [key: number]: FriendStatus },
    is_active: boolean;
    completed_tasks: number[];
    claimed_boosts: number[];
    promo_attempts: number;
    promos_claimed: number[];
    ref_code: string;
    ref_level: number;
    referrals: {[key: number]: number}
    referrals_tokens: {[key: number]: number}
    tap_power?: number;
    auto_tap_expire_at?: number;
    auto_tap_seconds?: number;
}

export interface Leader {
    id: number;
    username: string;
    avatar: string;
    tokens: number;
    friends: number;
    reward: number;
}

export interface BoostStatus {
    status: number;
    count: number;
    used_at: number;
    seconds_passed: number;
}

export interface TaskStatus {
    status: number;
    done_at: number;
}

export interface FriendStatus {
    active: boolean;
}

export interface Tokens {
    access_token: string;
    refresh_token: string;
    expires_in: number;
}

export interface Task {
    id: number;
    icon: string;
    title: string;
    url: string;
    reward: number;
}

export interface TaskStatus {
    status: number;
    done_at: number;
}


export interface Boost {
    id: number,
    icon: string,
    reward: number,
    title: string,
    description: string,
    type: number,
    max_count: number,
    price?: number;
    old_price?: number;
    tooltip?: string;
    invoice_url?: string;
}


export enum BoostType {
    FRIENDS = 0,
    DAILY_ENERGY,
    PURCHASE_TAPS,
    PURCHASE_ENERGY = 3,
    TOKENS = 4,
    AUTOTAP = 5,
    BUY_BAA = 100
}

export interface Promo {
    id: number;
    uses: number;
    max_uses: number;
    reward: number;
    seconds_left: number;
}


declare module 'react-virtualized' {
    export class AutoSizer extends React.Component<any, any> { }
    export class List extends React.Component<any, any> { }
}

