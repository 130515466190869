import ImageComponentBG from '../common/ImageComponentBG';
import SimpleButton from '../common/SimpleButton'
import Text from '../common/Text';
import s from './ModalHotOfferContent.module.css'

interface ModalHotOfferContentProps {
    closeModal: () => void;
    onClick: () => void;
    rewardSum: number;
}

const ModalHotOfferContent: React.FC<ModalHotOfferContentProps> = ({ closeModal, onClick, rewardSum }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>

            <div className={s.sheepImgBox}>
                <ImageComponentBG className={s.titleOnBase} imageUrl={"/images/hot_offer_base.webp"}>
                    <Text fontSize={1.5} lineHeight={1.5} uppercase={true}>hot offer</Text>
                </ImageComponentBG>
            </div>

            <div className={s.textBox}>
                <Text fontSize={1.5} lineHeight={2} uppercase={true}>hey bro!</Text>
                <Text fontSize={1} lineHeight={1.5} uppercase={true}>I have 5 easy tasks for you</Text>
                <Text fontSize={1} lineHeight={1.5} gradientColors={["--gradient-top", "--gradient-bot"]}>earning: ~1 min</Text>
            </div>


            <div className={s.btnEarnPosition}>
                <SimpleButton className={s.btnEarn} onClick={onClick} type="button" imageUrl={`/images/hot_offer_btn.webp`}>
                    <Text fontSize={1} lineHeight={1.5} uppercase={true}>earn</Text>
                    <img className={s.btnCoinImg} src={"/images/header/coin.webp"} alt="coin" />
                    <Text fontSize={1} lineHeight={1.5}>{rewardSum.toString()}</Text>
                </SimpleButton>
            </div>
        </div>
    )
}

export default ModalHotOfferContent