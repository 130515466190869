import s from './LeadersList.module.css';
import { Leader } from "../../redux/types";
import { GCS_URL } from "../../services/images";

const LeadersListItem: React.FC<{ item: Leader; index: number; currentPlayer: boolean; arrLength: number, isWeekly: boolean }> = ({ item, index, currentPlayer, arrLength, isWeekly }) => {

    const getListItemClassNamePos = (index: number) => {
        switch (index) {
            case 0:
                return s.goldPos;
            case 1:
                return s.silverPos;
            case 2:
                return s.bronzePos;
            default:
                return s.lowRatingPos;
        }
    };

    return (
        <div className={s.listItemBox}>
            {index < arrLength - 1 && <img className={s.ropesBG} style={{ animationDelay: `${index * 0.01}s` }} src={`/images/ropes.webp`} alt="ropes" />}
            <div className={`${currentPlayer ? s.listItemCP : s.listItem}`} style={{ animationDelay: `${index * 0.01}s` }}>
                <div className={s.persona}>
                    <div className={`${currentPlayer ? s.positionBGCP : s.positionBG}  ${getListItemClassNamePos(index)}`}>
                        <div className={`${currentPlayer ? s.positionTextCP : s.positionText}`}>{index !== -1 ? index + 1 : (isWeekly ? `25+` : `500+`)}</div>
                    </div>
                    <img className={s.avatarImg} src={item.avatar ? GCS_URL + item.avatar : '/images/header/coin.webp'} alt="avatar" />
                    <div className={s.personaBox}>
                        <p className={s.personaName}>{item.username}</p>
                        {!isWeekly && <div className={s.progressBox}>
                            <img className={s.coinImg} src={'/images/header/coin.webp'} alt="coin" />
                            <p className={s.progressText}>{item.tokens}</p>
                        </div>}

                        {isWeekly && <div className={s.progressBox}>
                            <img className={s.coinImg} src={'/images/tabbar/ic_tabbar_frens_act.webp'} alt="coin" />
                            <p className={s.progressText}>{item.friends}</p>
                        </div>}
                    </div>
                </div>
                <div className={`${currentPlayer ? s.rewardBoxCP : s.rewardBox}`}>
                    {item.reward > 0 && <img className={s.diamondImg} src={'/images/leaders/diamond.webp'} alt="diamond" />}
                    <p className={item.reward === 0 ? s.noRewardText : (currentPlayer ? s.rewardTextCP : s.rewardText)}>
                        {item.reward === 0 ? 'no reward' : `${item.reward} usdt`}
                    </p>
                </div>
            </div >
            {/* {index === arrLength - 1 && !currentPlayer && <div style={{ width: "91.11vw", aspectRatio: "360 / 58", height: "auto" }}></div>} */}
        </div >
    );
};

export default LeadersListItem;
